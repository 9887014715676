import React from 'react';
import { Link } from 'gatsby';

const Footer = () => (
  <footer className="footer">
    <div className="content has-text-centered">
      <p>© {new Date().getFullYear()} tech-steward.de <Link to="/impressum">Impressum</Link></p>
    </div>
  </footer>
);

export default Footer;