import React from 'react';
import { Link } from 'gatsby';

import logo from '../images/tech-steward-logo.svg';

const Header = ({ siteTitle = '' }) => (
  <header>
    <div className="container">
      <h1>
        <Link to="/">
          <img src={ logo } alt={ siteTitle }/>
        </Link>
      </h1>
    </div>
  </header>
);

export default Header;
